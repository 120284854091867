import {
  createRouter,
  createWebHistory
} from 'vue-router';
import Home from '../views/Home.vue';
// import Home from '../views/Home.vue';
import Master from '../back/master/index.vue';


const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/HomePage',
    meta: {
      keepAlive: true, //此组件需要被缓存
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/webSocket',
    name: 'webSocket',
    component: () => import('../views/webSocket.vue')
  },
  {
    path: '/HomePage', //首页
    name: 'HomePage',
    component: () => import('../views/HomePage/HomePage.vue')
  },
  {
    path: '/Contact', //联系我们
    name: 'Contact',
    component: () => import('../views/Contact/Contact.vue')
  },
  {
    path: '/File', //官方文件
    name: 'File',
    component: () => import('../views/File/File.vue')
  },
  {
    path: '/FileDetails', //官方文件
    name: 'details',
    component: () => import('../views/File/details.vue')
  },

  {
    path: '/ExamArrangement', //移动端
    name: 'ExamArrangement',
    component: () => import('../views/ExamArrangement/ExamArrangement.vue')
  },
  {
    path: '/appointment', //移动端预约
    name: 'appointment',
    component: () => import('../views/ExamArrangement/appointment.vue')
  },
  {
    path: '/appoTime', //移动端时间
    name: 'appoTime',
    component: () => import('../views/ExamArrangement/appoTime.vue')
  },


  //全国总决赛获奖奖金信息登记
  {
    path: '/BonusInformationRegistration', 
    name: 'BonusInformationRegistration',
    component: () => import('../views/BonusInformationRegistration/login.vue')
  },
  {
    path: '/InformationCompetition', 
    name: 'InformationCompetition',
    component: () => import('../views/BonusInformationRegistration/InformationCompetition.vue')
  },
  {
    path: '/paymentsInformation', 
    name: 'paymentsInformation',
    component: () => import('../views/BonusInformationRegistration/paymentsInformation.vue')
  },





  {
    path: '/Train', //大赛培训
    name: 'Train',
    component: () => import('../views/Train/Train.vue')
  },
  {
    path: '/Seventh', //第七届大唐杯官方文件总汇
    name: 'Seventh',
    component: () => import('../views/File/Seventh/Seventh.vue')
  },
  {
    path: '/Eighth', //第八届大唐杯官方文件总汇
    name: 'Eighth',
    component: () => import('../views/File/Eighth/Eighth.vue')
  },
  {
    path: '/Ninth', //第九届大唐杯官方文件总汇
    name: 'Ninth',
    component: () => import('../views/File/Ninth/Ninth.vue')
  },
  {
    path: '/Tenth', //第十届大唐杯官方文件总汇
    name: 'Tenth',
    component: () => import('../views/File/TenTh/Tenth.vue')
  },
  {
    path: '/fingerpost', //参赛指南
    name: 'fingerpost',
    component: () => import('../views/fingerpost/fingerpost.vue')
  },
  {
    path: '/Brief', //大赛简介
    name: 'Brief',
    component: () => import('../views/Brief/Brief.vue')
  },
  {
    path: '/Framework', //组织架构
    name: 'Framework',
    component: () => import('../views/Framework/Framework.vue')
  },
  {
    path: '/Retrospect', //往届回顾
    name: 'Retrospect',
    component: () => import('../views/Retrospect/Retrospect.vue')
  },
  {
    path: '/Journalism', //新闻动态
    name: 'Journalism',
    component: () => import('../views/Journalism/Journalism.vue')
  },
  {
    path: '/Platform', //平台介绍
    name: 'Platform',
    component: () => import('../views/Platform/Platform.vue')
  },
  {
    path: '/Regulations', //大赛章程
    name: 'Regulations',
    component: () => import('../views/Regulations/Regulations.vue')
  },
  {
    path: '/Register', //登录注册
    name: 'Register',
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/back', //注册
    name: 'back',
    component: () => import('../views/register/back.vue')
  },
  {
    path: '/registered', //找回密码
    name: 'registered',
    component: () => import('../views/register/registered.vue')
  },
  {
    path: '/ViewDetail', //大赛简介
    name: 'ViewDetail',
    component: () => import('@/views/ViewDetail/Brief.vue')
  },
  {
    path: '/TeachersGet', //教师赛页面
    name: 'TeachersGet',
    component: () => import('../views/Teachers/Teachers.vue')
  },
  {
    path: '/signup', //教师赛登录
    name: 'signup',
    component: () => import('../views/Teachers/signup.vue')
  },
  {
    path: '/innovation', //创新赛页面
    name: 'innovation',
    component: () => import('../views/innovation/innovation.vue')
  },
  {
    path: '/innovate', //创新赛验证页面
    name: 'innovate',
    component: () => import('../views/innovation/verify.vue')
  },
  {
    path: '/certificate', //证书查询页面
    name: 'certificate',
    component: () => import('../views/certificate/Certificate.vue')
  },
  {
    path: '/step', //创新赛步骤页面
    name: 'step',
    component: () => import('../views/innovation/step.vue')
  },
  {
    path: '/upload', //上传页面
    name: 'upload',
    component: () => import('../views/upload/upload.vue')
  },
  {
    path: '/Simulation', //仿真回放
    name: 'Simulation',
    component: () => import('../views/Simulation/Simulation.vue')
  },
  {
    path: '/Error', //错误页面
    name: 'Error',
    component: () => import('../views/errorPage/error.vue')
  },
  //后台管理页面
  {
    path: '/backHome',
    name: 'backHome',
    component: () => import('../back/home/index.vue'),
    redirect: "/Master",
    children: [{
        path: 'page',
        name: 'Teachers',
        title: '报名管理',
        children: [{
            path: '/datangCupNational',
            name: 'datangCupNational',
            title: '大唐杯全国大学',
            children: [{
                path: '/Teachers',
                name: 'Teachers',
                title: '省赛',
                component: () => import('../back/management/index.vue'),
              },
              {
                path: '/NationalFinals',
                name: 'NationalFinals',
                title: '全国总决赛',
                component: () => import('../back/nationalFinals/index.vue'),
              },
              {
                path: '/BeltRoadInvitational',
                name: 'BeltRoadInvitational',
                title: '一带一路邀请赛',
                component: () => import('../back/beltRoadInvitational/index.vue'),
              }
            ]
          },
          {
            path: '/doubleDivision',
            name: 'DoubleDivision',
            title: '双师型教师报名',
            component: () => import('../back/doubleDivision/index.vue')
          },
          {
            path: '/auditInformation',
            name: 'auditInformation',
            title: '审核信息',
            component: () => import('../back/auditInformation/index.vue')
          },
          {
            path: '/invoiceInformation',
            name: 'invoiceInformation',
            title: '发票开具',
            component: () => import('../back/invoiceInformation/index.vue')
          },
        ]
      },
      {
        path: 'prizeDisplay',
        name: 'prizeDisplay',
        title: '获奖信息管理',
        children: [{
            path: '/provincialDwards',
            name: 'ProvincialDwards',
            title: '省赛获奖公示',
            component: () => import('../back/provincialDwards/index.vue'),
          },
          {
            path: '/nationalPrizeAnnouncemen',
            name: 'nationalPrizeAnnouncemen',
            title: '全国赛获奖公示',
            component: () => import('../back/nationalShow/index.vue'),
          },
          {
            path: '/TeachPrizeAnnouncemen',
            name: 'TeachPrizeAnnouncemen',
            title: '教师赛获奖公示',
            component: () => import('../back/teachShow/index.vue'),
          },
        ]
      },
      {
        path: '/invigilation', //监考视频上传
        name: 'invigilation',
        component: () => import('../back/invigilation/invigilation.vue')
      },
      {
        path: '/certificateMailing', //证书邮寄地址
        name: 'CertificateMailing',
        component: () => import('../back/certificateMailing/certificateMailing.vue')
      },
      {
        path: '/ViewDetails',
        name: 'ViewDetails',
        title: '缴费信息',
        component: () => import('../back/step/index.vue')
      },
      {
        path: '/Info',
        name: 'Info',
        component: () => import('../back/inforManagement/index.vue')
      },
      {
        path: '/Master',
        name: 'Master',
        component: Master
      },
    ]
  },

  {
    path: '/Enrollment', //报名管理
    name: 'Enrollment',
    component: () => import('../back/management/index.vue')
  },
  // {
  //   path: '/Step',//下一步
  //   name: 'Step',
  //   component: () => import('../back/step/index.vue')
  // },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
   var listArray = ['Master', 'Teachers', 'NationalFinals', 'BeltRoadInvitational', 'doubleDivision', 'auditInformation','invoiceInformation', 'provincialDwards', 'nationalPrizeAnnouncemen', 'invigilation']
  let uid = JSON.parse(localStorage.getItem("UPuser"));
    listArray.map((item, index) => {
    if (to.name == item) {
      if (JSON.parse(localStorage.getItem("UPuser")) == undefined) {
        alert("登录已经过期，请重新登录！")
        window.location.href="/"
        next({
          path:'/HomePage'
        })
      }
    }
  next()
  });
})

export default router